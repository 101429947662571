<template>
  <div>
    <Spinner v-if="!cmsContentPending" class="mt-5 mb-5" size="medium" line-fg-color="#313189" />
    <footer v-else class="footer">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6">
            <div v-html="$prismic.asHtml(this.footer_company_name)" class="footer__company"></div>
            <span class="footer__line"></span>
            <div class="footer__box">
              <p v-for="(item, index) in this.footer_box_1" :key="`${index}`"><a :href="item.footer_link_url.url" class="footer__link">{{ $prismic.richTextAsPlain(item.footer_link_label) }}</a></p>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="footer__box footer__box--2">
              <p v-for="(item, index) in this.footer_box_2" :key="`${index}`"><a :href="item.footer_link_url.url" class="footer__link">{{ $prismic.richTextAsPlain(item.footer_link_label) }}</a></p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import Spinner from 'vue-simple-spinner'
export default {
  name: 'FooterComponent',
  components: {
    Spinner
  },
  data: () => {
    return {
      cmsContentPending: false,
      footer_company_name: null,
      footer_box_1: null,
      footer_box_2: null
    }
  },
  methods: {
    async getFooterContent () {
      await this.$prismic.client.getSingle('footer', { lang: this.$i18n.lang })
        .then((document) => {
          // footer section
          this.footer_company_name = document.data.footer_company_name
          this.footer_box_1 = document.data.footer_box_1
          this.footer_box_2 = document.data.footer_box_2
          this.cmsContentPending = true
        })
    }
  },
  created () {
    this.getFooterContent()
  },
  watch: {
    '$i18n.locale': function (newValue) {
      this.getFooterContent()
    }
  }
}
</script>

<style lang="scss">
  @import "./FooterComponent.scss";
</style>
